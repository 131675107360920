import React, { useEffect } from "react";
import aboutImage from "../../assets/images/about.png";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="my-20 py-10">
      <h2 className="text-primary font-semibold text-base mb-10 min-[960px]:mb-16 wrapper">
        About our company
      </h2>

      <div className="md:grid md:grid-cols-2">
        {/* image */}
        <div className="pl-[4%] md:pl-0 md:order-2">
          <div className="relative h-96">
            <div className="h-[90%] bg-primary absolute bottom-0 left-0 right-0" />

            <div className="h-[90%] w-[90%] bg-blue-500 absolute top-0 right-0">
              <img
                src={aboutImage}
                alt=""
                className="object-cover block w-full h-full"
              />
            </div>
          </div>
        </div>

        {/* text */}
        <div className="wrapper mt-16 md:mt-0 md:order-1">
          <div className="md:pr-[5%] md:wrapper">
            <h1 className="text-xl font-bold dark-text mb-5">About us</h1>

            <p className="text-sm mb-5">
              In a world where education plays a pivotal role in shaping the
              future, we believe in providing a seamless and innovative
              experience for schools, teachers, parents, and students alike.
              Welcome to our revolutionary School Management System, a
              comprehensive platform designed to transform education and propel
              it into a new era of excellence.
            </p>

            <p className="text-sm mb-5">
              At Get My Figures, we understand the unique challenges faced by
              educational institutions today. Managing administrative tasks,
              coordinating communication, monitoring student progress, and
              facilitating collaboration among stakeholders can be overwhelming.
              That's why we have developed a cutting-edge solution to streamline
              operations, enhance efficiency, and empower your educational
              journey.
            </p>

            <p className="text-sm mb-5">
              Our School Management System harnesses the power of technology to
              simplify and automate complex processes. With its intuitive
              interface and user-friendly features, it seamlessly integrates all
              aspects of school management into a single, unified platform. From
              attendance tracking to grading systems, from parent-teacher
              communication to resource management, our system effortlessly
              handles it all, freeing up valuable time and resources for what
              matters most—education.
            </p>

            <p className="text-sm mb-5">
              Imagine a world where administrative tasks are simplified,
              enabling educators to focus on nurturing young minds. With our
              School Management System, you can bid farewell to mountains of
              paperwork and say hello to a streamlined digital ecosystem that
              enhances productivity and collaboration. Our system empowers
              teachers to effortlessly manage class schedules, create
              interactive lesson plans, and monitor student performance,
              fostering a dynamic and engaging learning environment.
            </p>

            <p className="text-sm mb-5">
              We understand that open communication between teachers, parents,
              and students is the cornerstone of a successful educational
              journey. Our School Management System facilitates seamless
              communication channels, ensuring that important updates,
              notifications, and progress reports reach the right hands at the
              right time. Parents can actively participate in their child's
              education, staying informed and engaged every step of the way.
            </p>

            <p className="text-sm mb-5">
              Moreover, our system enables schools to efficiently manage
              resources, track inventory, and automate mundane tasks such as fee
              collection and payroll management. By optimizing operations, we
              empower schools to allocate their resources where they matter
              most—creating a transformative learning experience that inspires
              and prepares students for the challenges and opportunities of the
              future.
            </p>

            <p className="text-sm mb-5">
              At Get My Figures, we believe in the power of education to shape a
              brighter tomorrow. Our School Management System is not just a
              tool; it's a catalyst for change. Join us on this transformative
              journey and embrace the future of education. Together, let's
              unlock the full potential of every student, create a thriving
              educational ecosystem, and build a world where knowledge knows no
              bounds.
            </p>

            <p className="text-sm mb-5">
              Welcome to a new era of educational excellence with our School
              Management System. Get ready to revolutionize education, one click
              at a time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
