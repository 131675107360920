import React from "react";
import Logo from "./Logo";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";

export default function Sidebar({ toggleMenu }) {
  return (
    <div className="h-full">
      <div className="wrapper h-16 bg-white border-b border-gray">
        <div className="flex justify-between items-center">
          <Logo />

          <AiOutlineClose
            size={20}
            onClick={toggleMenu}
            className="cursor-pointer"
          />
        </div>
      </div>

      <div className="py-5 w-[55%] min-w-[230px] max-w-[300px] overview h-full">
        <div className="border-b">
          <NavLink to={"/about"}>
            <div className="wrapper py-2 mb-3 cursor-pointer hover:border-b hover:border-white">
              <p className="text-sm font-medium text-white">About Us</p>
            </div>
          </NavLink>

          <NavLink to={"/pricing"}>
            <div className="wrapper py-2 mb-3 cursor-pointer hover:border-b hover:border-white">
              <p className="text-sm font-medium text-white">Pricing</p>
            </div>
          </NavLink>

          <NavLink to={"/contact"}>
            <div className="wrapper py-2 mb-3 cursor-pointer hover:border-b hover:border-white">
              <p className="text-sm font-medium text-white">Contact Us</p>
            </div>
          </NavLink>

          <NavLink to={"/job"}>
            <div className="wrapper py-2 mb-3 cursor-pointer hover:border-b hover:border-white">
              <p className="text-sm font-medium text-white">Job</p>
            </div>
          </NavLink>

          <NavLink to={"/career"}>
            <div className="wrapper py-2 mb-3 cursor-pointer hover:border-b hover:border-white">
              <p className="text-sm font-medium text-white">Career</p>
            </div>
          </NavLink>
        </div>

        <div className="py-5 wrapper">
          <button className="border text-sm font-medium h-10 w-[40%] rounded-[4px] border-white bg-white text-black mr-3">
            Log in
          </button>

          <button className="border text-sm font-medium h-10 w-[40%] rounded-[4px] border-purple bg-primary text-white">
            Sign up
          </button>
        </div>
      </div>

      {/* <div>buttons</div> */}
    </div>
  );
}
