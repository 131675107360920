import hero1 from "../../../assets/images/hero1.png";
import { FaLongArrowAltRight } from "react-icons/fa";

export default function Hero() {
  return (
    <div className="mt-20 py-5 wrapper min-[900px]:grid min-[900px]:grid-cols-2">
      <div className="mb-14 pt-7 min-[900px]:mb-0 xl:pt-16">
        <div className="min-[900px]:pr-[10%]">
          <h6 className="text-black font-semibold text-base mb-10 min-[960px]:mb-16">
            Academic Tracking Platform
          </h6>

          <h1 className="font-semibold text-3xl mb-7 min-[960px]:mb-10 min-[960px]:text-4xl lg:text-5xl xl:text-5xl">
            All Tools You Need To Track Your Kids Academic Performance
          </h1>

          <h5 className="text-base text-grey mb-7 min-[960px]:mb-10 xl:mb-12">
            We have currated list of features that enables schools and parent to
            track students performances
          </h5>

          <div className="flex items-center gap-5">
            <a
              href={"https://school.getmyfigures.com"}
              target="_blank"
              rel="noreferrer"
            >
              <button className="flex items-center justify-center rounded-[4px] bg-primary py-2 px-3 min-[960px]:py-2 xl:py-3">
                <p className="text-white text-sm md:text-base font-medium mr-2">
                  School Platform
                </p>
                <FaLongArrowAltRight className="text-white" />
              </button>
            </a>

            <a
              href={"https://teacher.getmyfigures.com"}
              target="_blank"
              rel="noreferrer"
            >
              <button className="flex items-center justify-center rounded-[4px] bg-primary py-2 px-3 min-[960px]:py-2 xl:py-3">
                <p className="text-white text-sm md:text-base font-medium mr-2">
                  Teacher Platform
                </p>
                <FaLongArrowAltRight className="text-white" />
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="">
        <div className="min-[700px]:h-[600px] min-[700px]:w-[600px] min-[700px]:mx-auto min-[900px]:w-full min-[900px]:h-full xl:max-h-[600px] 2xl:max-h-[700px]">
          <img
            src={hero1}
            alt=""
            className="h-full w-full object-contain block"
          />
        </div>
        {/* <div className="relative min-[700px]:h-[600px] min-[700px]:w-[600px] min-[700px]:mx-auto min-[900px]:w-full min-[900px]:h-full xl:max-h-[600px] 2xl:max-h-[700px]">
          <img
            src={studentImage}
            alt=""
            className="h-full w-full object-contain block"
          />
          <img
            src={assitedImage}
            alt=""
            className="object-contain block w-[45%] absolute top-0 left-0"
          />
          <img
            src={admittedImage}
            alt=""
            className="object-contain block w-[35%] absolute bottom-[15%] left-0"
          />
          <img
            src={admittedImage}
            alt=""
            className="object-contain block w-[40%] absolute bottom-0 right-[13%]"
          />
        </div> */}
      </div>
    </div>
  );
}
